<template>
<tbody class="table-font" style="font-size:13px;" v-if="item!=null">
    <tr>
        <td>{{index+1}}</td>
        <td>{{item.customer?.generatedid}}</td>
        <td><span v-if="item.customer!=null">{{item.customer.name}}</span></td>
        <td><display-mobiles :mobiles="item.customer?.mobiles"></display-mobiles></td>
        <td><display-first-address :addresses="item.customer?.addressess"></display-first-address></td> 
        <td>{{item.complaintno}}</td> 
        <td>{{item.remark}}</td>
        
        <!-- <td>{{leadbymethod(item)}}</td> -->
        <td>{{item.leaddate!=null?moment(item.leaddate).format('DD-MM-YYYY'):"N.A"}}</td>
        <td style="font-size:10px ;">
            <div>
                <div>
                    <span v-if="item.product!=null && item.product.category!=null">{{item.product.category.name}}</span>
                    <span v-if="item.product!=null && item.product.attribute!=null">
                        <div v-for="company in item.product.attribute" :key="company.id">
                        {{ getcompany(company.attribute_value_ids) }}
                        </div>
                    </span>
                    <span >{{item.product.name}}</span>&nbsp;
                </div>
            </div>
        </td>
        <td>{{item.scheduledate!=null?moment(item.scheduledate).format('DD-MM-YYYY'):"N.A"}}</td>
        <td><span v-if="item.enteredby!=null">{{item.enteredby.name}}<br>
            {{moment(item.created_at).format('DD-MM-YYYY')}}</span>
        </td>
        <td><span :style="item.status==0?'color:red':'color:green'">{{ item.status==0?'Pending':'Resolved'}}</span></td>
        <td><button class="badge btn-prime btn-xs" @click="showchats()">conversation</button></td>
        <td @dblclick="showfiled=!showfiled" @keyup.enter="showfiled=!showfiled">
            <span v-if="item.followby!=null">{{item.followby.name}}</span>
            <select v-if="showfiled" v-model="followedid" class="form-control form-cascade-control input-small" @change="showfollowedfield(item)">
                <option v-for="filter in employees" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
            </select> 
        </td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="13">
            <customer-conversation :item="item" :show="true"></customer-conversation>
        </td>
    </tr>
</tbody>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import CustomerConversation from '../complaint/CustomerConversation.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue'
export default {
    props:['item','index'],
    components:{CustomerConversation,DisplayFirstAddress,DisplayMobiles},
    mixins:[Constants],
    data() {
        return {
            moment:moment,
            visitcomments:false,
            followedid:0,
            showfiled:false
        }
    },
    computed:{
        ...mapGetters(['employees','loggedinuser','attributes'])
    },
    mounted() {
        if(this.item.followby!=null){
            this.followedid=this.item.followby.id
        }
        this.$store.dispatch('fetchemployees')
    },
    methods: {
        showchats(){
            this.visitcomments=!this.visitcomments
        },
        requestmethod(item){
            let type="";
            switch (item.requesttype) {
                case 1:
                    type= "QUERY";
                    break;
                case 2:
                    type= "Complaint";
                    break;
                case 3:
                    type= "Feedback";
                    break;
                case 4:
                    type= "ORDER";
                    break;
                default:
                    break;
            }
            return type;
        },
        leadbymethod(item){
            let type="";
            switch (item.leadtype) {
                case 1:
                    type= "INCOMING CALL";
                    break;
                case 2:
                    type= "OUTGOING CALL";
                    break;
                case 3:
                    type= "NEWS PAPER ADD";
                    break;
                case 4:
                    type= "SHOW ROOM WALKIN";
                    break;
                case 5:
                    type= "HOME VISIT";
                    break;
                case 6:
                    type= "REFFERRED BY OTHER";
                    break;
                case 7:
                    type= "INTERNET";
                    break;
                case 8:
                    type= "BY ADVISOR";
                    break;
                default:
                    break;
            }
            return type;
        },
        refresh(){
            this.$parent.refresh()
        },
        // updatefield(){

        // },
        showfollowedfield(item){
            if(this.loginusercheck(170)){
                this.showfiled=!this.showfiled
                let param={id:item.id,column:"followedid",value:this.followedid}
                this.$http.post('api/leads/updatefield',param)
                    .then((response) =>this.processUpdateResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }else{
                alert('You Dont have Right')
            }
        },
        processUpdateResponse(data){
            // this.showfiled=false
            this.$parent.refresh()
            this.$notify({
                text:'Field Updated Successfully',data,
                type:'success',
                duration:'-1',
                speed:'100',
                closeOnClick:false
            })
        },
       
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
    },
}
</script>